import { formatISO, isDate } from 'date-fns';
import * as yup from 'yup';
import { COUNTRIES, INCOTERMS, ISRI_GRADE, METAL_TYPE } from '@frontend/api';
import {
  FORM_ERRORS_TYPES,
  MAX_FILE_SIZE,
  MIN_REQUIRED_FILES,
  SUPPORTED_FORMATS,
} from './ListingForm.const';
import { MIN_QUANTITY_REQUIRED_MT } from '../Forms.const';
import { isValidDate } from '../../../utils/date.utils';

type ListingFormSchemaTranslations = {
  required: string;
  fileSize: string;
  fileType: string;
  minNumberOfFiles: string;
  maxNumbersAfterDecimal: string;
  minQuantityRequired: string;
  dateMustBeFuture: string;
  dateMustBeValid: string;
};
function fileSizeValidation(file: File) {
  return file.size <= MAX_FILE_SIZE;
}

function fileTypeValidation(file: File) {
  return SUPPORTED_FORMATS.includes(file.type);
}

function maxNumbersAfterDecimalValidation(number: number) {
  return /^\d+(\.\d{0,5})?$/.test(number.toString());
}

function dateIsNotPast(date: string | undefined) {
  return !date || (isValidDate(date) && new Date() < new Date(date));
}
function dateIsInvalid(date: string | undefined) {
  if (date === FORM_ERRORS_TYPES.INVALID_DATE) {
    return false;
  } else {
    return true;
  }
}

export default function getListingFormSchema(
  translations: ListingFormSchemaTranslations,
) {
  return yup.object().shape({
    metalType: yup
      .string()
      .oneOf(METAL_TYPE, translations.required)
      .required(translations.required),
    isriGrade: yup
      .string()
      .oneOf(['', ...ISRI_GRADE])
      .required(translations.required),
    validity: yup
      .string()
      .transform((value: string, originalValue: Date) => {
        return isDate(originalValue) ? formatISO(originalValue) : value;
      })
      .test(
        FORM_ERRORS_TYPES.INVALID_DATE,
        translations.dateMustBeValid,
        dateIsInvalid,
      )
      .test(
        FORM_ERRORS_TYPES.IS_FUTURE_DATE,
        translations.dateMustBeFuture,
        dateIsNotPast,
      )
      .required(translations.required),
    quantity: yup
      .number()
      .min(MIN_QUANTITY_REQUIRED_MT, translations.minQuantityRequired)
      .required(translations.required)
      .test(
        'maxNumbersAfterDecimal',
        translations.maxNumbersAfterDecimal,
        maxNumbersAfterDecimalValidation,
      )
      .transform((val, orig) => (orig === '' ? undefined : val)),
    incoterms: yup
      .string()
      .oneOf(['', ...INCOTERMS])
      .required(translations.required),
    shippingFrom: yup.string().optional(),
    materialFromCountry: yup
      .string()
      .oneOf(['', ...COUNTRIES], translations.required)
      .required(translations.required),
    preferredPaymentTerm: yup.string().optional(),
    materialPhotos: yup
      .array()
      .of(
        yup
          .mixed<File>()
          .test(
            'fileType',
            translations.fileType,
            file => file && fileTypeValidation(file),
          )
          .test(
            'fileSize',
            translations.fileSize,
            file => file && fileSizeValidation(file),
          )
          .required(),
      )
      .min(MIN_REQUIRED_FILES, translations.minNumberOfFiles)
      .required(),
  });
}
