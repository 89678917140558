import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const PlaceholderImageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.neutral[50],
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  minHeight: '126px',
  padding: '16px 8px',
  cursor: 'pointer',
  '& img': {
    height: '24px',
  },
  '& p': {
    textAlign: 'center',
    maxWidth: '125px',
  },
  [theme.breakpoints.down('tablet')]: {
    '& img': {
      height: '18px',
      marginBottom: '4px',
    },
    '& p': {
      fontSize: '10px',
      lineHeight: '10px',
    },
  },
}));

export const PlaceholderSlideWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.neutral[50],
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  '& img': {
    height: '54px',
    marginBottom: '18px',
  },
  '& p': {
    textAlign: 'center',
    maxWidth: '280px',
  },
}));
