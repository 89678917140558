import { IContainer, IRequestOrder } from './getRequestOrderList.types';
import { format } from 'date-fns';

const containersWithData: IContainer[] = [
  {
    uuid: 'ec9de6ca-75f9-3ab5-bafc-cb97564cd167',
    createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
    containerNumber: 'ABCD1234567',
    sealNumber: 'Seal number',
    netWeight: 1000,
    tare: 1000,
    total: 2000,
  },
  {
    uuid: 'ec9de6ca-75f9-3ab5-bafc-cb973cd17',
    createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
    containerNumber: 'ABCDE43231',
    sealNumber: 'Seal number',
    netWeight: 100,
    tare: 0,
    total: 100,
  },
];

const containersWithoutData: IContainer[] = [
  {
    uuid: 'ec9de6ca-75f9-3ab5-bafc-cb97564cd167',
    createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
    containerNumber: 'ABCD1234567',
    sealNumber: '',
    netWeight: null,
    tare: null,
    total: null,
  },
];
export const mockedOrder: IRequestOrder = {
  uuid: 'ec9de6ca-75f9-4ab5-bafc-cb97564cd167',
  status: 'order.status.in_transit',
  publicId: 'DEF',
  orderNumberId: '123456',
  quantity: 1000.2324,
  createdDate: format(new Date(Date.now()), 'dd.MMM.yyyy'),
  shippingTo: 'Las Palmas de G.C.',
  cost: 234234.234,
  annexViiUrl: 'url',
  billOfLadingUrl: 'url',
  certificateOfOriginUrl: 'url',
  finalInvoiceUrl: 'url',
  greenListContractUrl: 'url',
  nonRadioactiveCertificateUrl: 'url',
  packListUrl: 'url',
  documents: [],
  containers: containersWithData,
};

export const mockedOrderWithMissingContainersInfo: IRequestOrder = {
  ...mockedOrder,
  containers: containersWithoutData,
};
