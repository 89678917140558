export const SUPPORTED_MIME_IMAGE_FORMATS = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
];

export const SUPPORTED_IMAGE_DOCUMENT_FORMATS = ['pdf'];

export const SUPPORTED_IMAGE_FORMATS = ['gif', 'jpg', 'jpeg', 'png', 'svg'];
