import { createContext, useCallback, useEffect, useReducer } from 'react';
import { IAuthContext, IAuthContextProvider } from './AuthContext.types';
import {
  ICreateSessionRequest,
  IEmailVerificationPayload,
  useCreateSession,
  useDestroySession,
  useEmailVerificationMutation,
  useGetCurrentUser,
  USER_ROLE_TYPE_ENUM,
} from '@frontend/api';
import { getUserId, removeUserId, setUserId } from '@frontend/tracking';
import {
  EMAIL_VERIFICATION_FAILED,
  EMAIL_VERIFICATION_PROGRESS,
  EMAIL_VERIFICATION_SUCCESS,
  LOGIN_FAILED,
  LOGIN_PROGRESS,
  LOGIN_SUCCESS,
  LOGOUT,
  authReducer,
} from './AuthContext.utils';

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  authenticationInProgress: true,
  authenticationNotStarted: true,
  isEmailVerificationInProgress: true,
  isEmailVerificationNotStarted: true,
  isEmailVerificationSuccess: false,
  isEmailVerificationFail: false,
  isCurrentUserBuyer: false,
  isCurrentUserSeller: false,
});

export default function AuthContextProvider({
  children,
}: IAuthContextProvider) {
  const { mutate: createSession, status: createSessionStatus } =
    useCreateSession();
  const { mutate: verifyEmail, status: emailVerificationStatus } =
    useEmailVerificationMutation();

  const { mutate: destroySession } = useDestroySession();

  const [state, dispatch] = useReducer(authReducer, {
    authenticationStatus: 'idle',
    emailVerificationStatus: 'idle',
  });

  const {
    mutate: getCurrentUser,
    status: getCurrentUserStatus,
    data: currentUserResponse,
  } = useGetCurrentUser();

  const login = useCallback(
    (createSessionRequest: ICreateSessionRequest) => {
      dispatch({ type: LOGIN_PROGRESS });
      createSession(createSessionRequest);
    },
    [createSession],
  );

  const emailVerification = useCallback(
    (emailVerificationPayload: IEmailVerificationPayload) => {
      dispatch({ type: EMAIL_VERIFICATION_PROGRESS });
      verifyEmail(emailVerificationPayload);
    },
    [verifyEmail],
  );

  const logout = useCallback(() => {
    destroySession();
    removeUserId();
    dispatch({ type: LOGOUT });
  }, [destroySession]);

  const checkSession = useCallback(() => {
    dispatch({ type: LOGIN_PROGRESS });
    getCurrentUser();
  }, [getCurrentUser]);

  const isCurrentUserBuyer = useCallback(() => {
    return (
      !!state.currentUser &&
      [
        USER_ROLE_TYPE_ENUM.BUYER,
        USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
      ].includes(state.currentUser.role)
    );
  }, [state.currentUser]);

  const isCurrentUserSeller = useCallback(() => {
    return (
      !!state.currentUser &&
      [
        USER_ROLE_TYPE_ENUM.SELLER,
        USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
      ].includes(state.currentUser.role)
    );
  }, [state.currentUser]);

  useEffect(() => {
    if (createSessionStatus === 'success') {
      getCurrentUser();
    } else if (createSessionStatus === 'error') {
      dispatch({ type: LOGIN_FAILED });
    }
  }, [createSessionStatus, getCurrentUser]);

  useEffect(() => {
    if (emailVerificationStatus === 'success') {
      dispatch({ type: EMAIL_VERIFICATION_SUCCESS });
      getCurrentUser();
    } else if (emailVerificationStatus === 'error') {
      dispatch({ type: EMAIL_VERIFICATION_FAILED });
    }
  }, [emailVerificationStatus, getCurrentUser]);

  useEffect(() => {
    if (getCurrentUserStatus === 'success') {
      dispatch({ type: LOGIN_SUCCESS, payload: currentUserResponse?.data });
      // Register userID for tracking
      const recordedUser = getUserId();
      if (
        currentUserResponse &&
        (!recordedUser || recordedUser !== currentUserResponse.data.uuid)
      ) {
        setUserId(currentUserResponse.data.uuid);
      }
    } else if (getCurrentUserStatus === 'error') {
      dispatch({ type: LOGIN_FAILED });
    }
  }, [getCurrentUserStatus, currentUserResponse]);

  return (
    <AuthContext.Provider
      value={{
        login,
        emailVerification,
        logout,
        checkSession,
        currentUser: state.currentUser,
        isCurrentUserBuyer: isCurrentUserBuyer(),
        isCurrentUserSeller: isCurrentUserSeller(),
        isAuthenticated: !!state.currentUser,
        authenticationInProgress: state.authenticationStatus === 'pending',
        authenticationNotStarted: state.authenticationStatus === 'idle',
        isEmailVerificationInProgress:
          state.emailVerificationStatus === 'pending',
        isEmailVerificationNotStarted: state.emailVerificationStatus === 'idle',
        isEmailVerificationSuccess: state.emailVerificationStatus === 'success',
        isEmailVerificationFail: state.emailVerificationStatus === 'error',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
