import { MainWrapper } from './OrderCardDetails.css';
import { IOrderCardDetails } from './OrderCardDetails.types';
import OrderBasicDetails from './OrderBasicDetails/OrderBasicDetails';
import OrderDocuments from './OrderDocuments/OrderDocuments';
import OrderPhotos from './OrderPhotos/OrderPhotos';

export default function OrderCardDetails({
  order,
  refs: { orderBasicDetailsSectionRef },
  onOrderUpdate,
}: IOrderCardDetails) {
  return (
    <MainWrapper>
      <OrderBasicDetails
        order={order}
        orderBasicDetailsSectionRef={orderBasicDetailsSectionRef}
      />
      <OrderPhotos
        orderId={order.uuid}
        alreadyUploadedFiles={order.photos}
        onOrderUpdate={onOrderUpdate}
      />
      <OrderDocuments
        orderId={order.uuid}
        alreadyUploadedFiles={order.documents}
        onOrderUpdate={onOrderUpdate}
      />
    </MainWrapper>
  );
}
