import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import {
  IEmailVerificationPayload,
  IEmailVerificationResponseFailure,
} from './emailVerification.types';

const submitEmailVerification = (
  { token }: IEmailVerificationPayload,
  apiUrl: string,
) => {
  return axiosClient.get(
    `${apiUrl}/accounts/user/email-verification/${token}/`,
  );
};

const useEmailVerificationMutation = () => {
  const { apiUrl } = useContext(ApiContext);
  return useMutation<
    AxiosResponse,
    AxiosError<IEmailVerificationResponseFailure>,
    IEmailVerificationPayload
  >({
    mutationFn: (emailVerificationData: IEmailVerificationPayload) =>
      submitEmailVerification(emailVerificationData, apiUrl),
  });
};

export default useEmailVerificationMutation;
