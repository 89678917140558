import {
  RemixiconComponentType,
  RiArrowRightUpLine,
  RiCheckDoubleFill,
  RiFundsBoxLine,
  RiMailSendLine,
  RiStockFill,
  RiTreasureMapLine,
  RiUserAddLine,
  RiWhatsappLine,
} from '@remixicon/react';
import { IconWrapper } from './Icon.css';
import { IconProps } from './Icon.types';
import PdfIcon from './assets/PdfIcon';

export default function Icon({ color, size = 20, variant }: IconProps) {
  const ICON_MAPPING = {
    'user-add': RiUserAddLine,
    'check-double': RiCheckDoubleFill,
    'mail-send': RiMailSendLine,
    'funds-box': RiFundsBoxLine,
    stock: RiStockFill,
    'treasure-map': RiTreasureMapLine,
    'arrow-right-up': RiArrowRightUpLine,
    whatsapp: RiWhatsappLine,
    pdf: PdfIcon,
  } as Record<string, RemixiconComponentType>;

  const Icon = ICON_MAPPING[variant];

  if (Icon) {
    return variant === 'pdf' ? (
      <IconWrapper color={color} size={size}>
        <Icon />
      </IconWrapper>
    ) : (
      <Icon color={color} size={size} />
    );
  }

  return null;
}
