import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button, SideWrapper } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  ButtonAndTextWrapper,
  HeaderWrapper,
  MainContainer,
} from './OrderOverview.css';
import { IOrderOverview } from './OrderOverview.types';
import { RiArrowRightSLine } from '@remixicon/react';
import { RiArrowLeftLine } from '@remixicon/react';
import OrderStatusBar from '../../../../../../app/shared/components/OrderStatusBar/OrderStatusBar';
import { REQUEST_ORDER_CARD_STAGES } from '../../../../../../app/shared/components/OrderStatusBar/orderStatusBar.const';

export default function OrderOverview({
  order,
  isMobile = false,
  isOrderSelected = false,
  activeStep,
}: IOrderOverview) {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    const requestDetailsPage = generatePath(PLATFORM_ROUTES.requestDetails, {
      uuid: uuid as string,
    });
    navigate(requestDetailsPage);
  };

  if (isMobile && isOrderSelected) {
    return (
      <MainContainer>
        <HeaderWrapper selected={isOrderSelected}>
          <OrderStatusBar
            activeStep={activeStep}
            stages={REQUEST_ORDER_CARD_STAGES}
          />
          <SideWrapper>
            <ButtonAndTextWrapper>
              <Button
                buttonType="linked"
                id="order-overview.button.back-to-orders"
                linkedButtonColorValue="black"
                startIcon={<RiArrowLeftLine size={24} />}
                size="large"
                onClick={handleClick}
              >
                <Translate id="customer-platform.request-details.order-card.header-previous-page-cta" />
              </Button>
              <Typography variant={isMobile ? 'h4' : 'h5'}>
                <Translate
                  id="customer-platform.request-details.order-card.order-number"
                  values={{
                    ORDER_NUMBER_ID: order.orderNumberId,
                  }}
                />
              </Typography>
            </ButtonAndTextWrapper>
          </SideWrapper>
        </HeaderWrapper>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <HeaderWrapper selected={isOrderSelected}>
        <Typography variant={isMobile ? 'h5' : 'h6'}>
          <Translate
            id="customer-platform.request-details.order-card.order-number"
            values={{
              ORDER_NUMBER_ID: order.orderNumberId,
            }}
          />
        </Typography>
        {isMobile && <RiArrowRightSLine size={16} />}
      </HeaderWrapper>
      {isMobile && (
        <OrderStatusBar
          isMobile={isMobile}
          activeStep={activeStep}
          stages={REQUEST_ORDER_CARD_STAGES}
        />
      )}
    </MainContainer>
  );
}
